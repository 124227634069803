import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import './Console/Console.css'
import { useNavigate } from "react-router-dom";
import logo from '../assets/kearneyonlineLogo.png';
import { getAuth } from "firebase/auth";
import { useFirebase } from "../utils/Firebase";
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import handleCommand from "./Console/handleCommand";

function Console() {

    const [messages, setMessages] = useState<Array<string>>([])
    const [userMessages, setUserMessages] = useState<Array<string>>([])
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();
    const { app } = useFirebase();
    const auth = getAuth(app);

    const addMessage = (message: string) => {
        // Add each message as a string with a timestamp like this:
        // [<timestamp>]: <message>
        // Then, set the messages state to the new array.
        setMessages([...messages, `[${new Date().toLocaleTimeString()}]: ${message}`])
    }

    const addUserMessage = (message: string) => {
        // Add the user's raw input to the userMessage state.
        setUserMessages([...userMessages, message]);
    }

    const handleNewConsoleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // If the user presses enter, add the message to the console.
        if (event.key === 'Enter') {
            setIsSubmitting(true);
            const userInput = event.currentTarget.value;
            const message = handleCommand(userInput, () => setMessages([`[${new Date().toLocaleTimeString()}]: Welcome, ${auth?.currentUser?.displayName || 'User'}!`]));

            // If the message starts with 'open', navigate to the specified path (the first argument).
            if (userInput?.startsWith('open')) {
                const path = userInput.split(' ')[1];
                navigate(`/${path}`);
            } else if (message) {
                addMessage(message);
                addUserMessage(userInput);
            }
            event.currentTarget.value = '';
        }

        // If the user presses the up arrow, get the last message from the userMessages array.
        if (event.key === 'ArrowUp') {
            event.currentTarget.value = userMessages[userMessages.length - 1] || '';
        }

        // If the user presses the down arrow, clear the input.
        if (event.key === 'ArrowDown') {
            event.currentTarget.value = '';
        }

        setIsSubmitting(false);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                addMessage(`Welcome, ${user.displayName || 'User'}!`);
                setIsLoading(false);
            }
        });
    
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
    
        return () => {
            unsubscribe();
            clearInterval(timer);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <motion.div className="App"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >

            <motion.div className='header'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}
                >
                <div className='date-time'>
                    <div className='logo-container'
                    onClick={() => navigate('/')}
                    >
                    <img src={logo} alt='logo' className='logo'/>
                    Kearney Labs
                    </div>
                    <div className='time'>
                    {time.toLocaleTimeString()}
                    </div>
                </div>
            </motion.div>


            {isLoading ? 
                (
                <div className='body' 
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh'}}
                >
                    <HourglassFullIcon className="loading-icon"/>
                </div>
                ) 
                : (
                    <div>
                        <div className="console">
                            {messages.map((message, index) => {
                                return (
                                    <div key={index}>
                                        {message}
                                    </div>
                                )
                            }
                            )}
                        </div>

                        <div className="footer" style={{marginBottom: '0.5rem'}}>
                            {isSubmitting ? 'Submitting...' : 
                                <input type="text" className="console-input" 
                                onKeyDown={handleNewConsoleInput}
                                placeholder="Enter a command..."
                            />}
                        </div>
                    </div>
                )}
            
        </motion.div>
    );
}

export default Console;