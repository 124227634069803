import logo from '../assets/kearneyonlineLogo.png';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {

    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <motion.div className='App'>
                <motion.div className='header'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0 }}
                >
                    <div className='date-time'>
                        <div className='logo-container'
                        onClick={() => navigate('/')}
                        >
                        <img src={logo} alt='logo' className='logo'/>
                        Kearney Labs 
                        </div>
                        <div className='time'>
                        {time.toLocaleTimeString()}
                        </div>
                    </div>
                </motion.div>

                <motion.div className='body' style={{ padding: '1rem', width: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <h1>Privacy Policy</h1>
                    <h5>Last Updated: September 1, 2023</h5>

                    <h2>1. Introduction</h2>

                    <p>Welcome to the Privacy Policy of LabKit, provided by Kearney Labs ("we," "our," or "us"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you access and use the LabKit service, available at https://tools.kearneyjohn.com ("LabKit" or "the Service"). By using LabKit, you consent to the practices described in this Privacy Policy.</p>

                    <h2>2. Information We Collect</h2>

                    <h3>2.1. Personal Information</h3>

                    <p>To provide you with access to LabKit, we collect the following personal information when you sign up using your Google account:</p>

                    <ul>
                        <li>Name</li>
                        <li>Email Address</li>
                        <li>Profile Picture</li>
                        <li>Google Authentication Token</li>
                    </ul>

                    <p>We use this information exclusively for authentication and providing LabKit services.</p>

                    <h2>3. Data Security</h2>

                    <p>We take data security seriously. We employ industry-standard Backend as a Service (BAAS) solutions, such as Google Firebase, to safeguard your data. However, please be aware that LabKit is in an alpha development stage, and while we strive for the highest security standards, vulnerabilities may exist.</p>

                    <h2>4. How We Use Your Data</h2>

                    <p>When you use LabKit and upload information, you consent to us sharing non-sensitive data with third-party services as necessary for LabKit's functionalities, such as audio fingerprinting and AI-enabled mail summarization. We ensure that only the minimum required information is shared, and sensitive data is never disclosed.</p>

                    <h2>5. Data Collection and Storage</h2>

                    <p>We exclusively collect and store the data you provide during the sign-in process. LabKit does not retain audio files, user emails, user email metadata, or any other sensitive information.</p>

                    <h2>6. Third-Party Services</h2>

                    <p>LabKit may utilize third-party APIs to enhance your experience by consolidating information. Rest assured, we are committed to protecting your data and will never share sensitive information with these third-party services.</p>

                    <h2>7. Updates and Changes</h2>

                    <p>LabKit is actively evolving, and we reserve the right to introduce new tools and features at any time. The decision to use these additional tools is entirely yours.</p>

                    <h2>8. Your Data Control</h2>

                    <p>You have the authority to request data deletion or access your data at any time. Upon deletion, all your information is permanently removed and cannot be retrieved.</p>

                    <h2>9. Ownership and Intellectual Property</h2>

                    <p>John Kearney, representing Kearney Labs, holds all rights to LabKit's code. We do not assert ownership or copyright over any information you upload to the website; our ownership pertains solely to the code.</p>

                    <h2>10. Termination</h2>

                    <p>Kearney Labs retains the right to terminate any LabKit account without prior notice or justification.</p>

                    <h2>11. Disclaimer of Warranties</h2>

                    <p>As LabKit is in active development, Kearney Labs (or any affiliated individuals) disclaim responsibility or liability for any damages resulting from service use. Users assume all risks associated with its usage.</p>

                    <h2>12. Contact Us</h2>

                    <p>If you require support or have inquiries, you can reach us at dev@kearneyjohn.com. Additionally, you may receive occasional product updates or Privacy Policy notifications via email from noreply@kearneyjohn.com.</p>

                    <h2>13. Governing Law</h2>

                    <p>This Privacy Policy is governed by and construed in accordance with the laws of North Carolina, United States of America. Any disputes arising from or related to this Privacy Policy are subject to the exclusive jurisdiction of the United States of America courts.</p>

                    <h2>14. Changes to Privacy Policy</h2>

                    <p>LabKit may update this Privacy Policy at any time. Your continued use of the Service after such changes constitutes acceptance of the revised Privacy Policy. If you disagree with the revised Privacy Policy, please discontinue using the Service and contact us for data deletion.</p>
                </motion.div>


            </motion.div>
    )
}

export default PrivacyPolicy;