import logo from '../assets/kearneyonlineLogo.png';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Error() {

    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <motion.div className='App'>
                <motion.div className='header'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0 }}
                >
                    <div className='date-time'>
                        <div className='logo-container'
                        onClick={() => navigate('/')}
                        >
                        <img src={logo} alt='logo' className='logo'/>
                        Kearney Labs 
                        </div>
                        <div className='time'>
                        {time.toLocaleTimeString()}
                        </div>
                    </div>
                </motion.div>

                <motion.div className='body' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80vh'}}>
                    <h2>Oops! There's nothing here!</h2>
                    <img src={logo} alt='logo' className='loading-icon' style={{width: '2rem', filter: 'invert(1)'}}/>
                </motion.div>

            </motion.div>
    )
}

export default Error;