// NEW

import React, { createContext, useContext, ReactNode } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";

// Define the shape of the context
interface FirebaseContextType {
  app: FirebaseApp;
}

// Create the Firebase context
const FirebaseContext = createContext<FirebaseContextType | undefined>(undefined);

// Create a custom hook to access the Firebase context
export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  authDomain: "kearney-lab.firebaseapp.com",
  databaseURL: "https://kearney-lab-default-rtdb.firebaseio.com",
  projectId: "kearney-lab",
  storageBucket: "kearney-lab.appspot.com",
  messagingSenderId: "1046510116186",
  appId: "1:1046510116186:web:ebb88bc4be405168f32763",
  measurementId: "G-FVV7B0WS9G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Define the type for children prop
interface FirebaseProviderProps {
  children: ReactNode;
}

// Create the FirebaseProvider component
export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
  const firebaseContextValue: FirebaseContextType = {
    app,
  };

  return (
    <FirebaseContext.Provider value={firebaseContextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

