import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FirebaseProvider } from "./utils/Firebase";
import { Analytics } from '@vercel/analytics/react';
import MailKit from './tools/MailKit/MailKit';

// Tools
import Fingerprinting from './tools/Fingerprinting';
import Account from './tools/Account';
import Console from './tools/Console';
import PrivacyPolicy from './staticpages/privacyPolicy';
import TOS from './staticpages/tos';
import Error from './staticpages/error';

interface RouteTransitionProps {
  children: ReactNode;
}

const RouteTransition: React.FC<RouteTransitionProps> = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FirebaseProvider>
    <Analytics />
      <Router>
        <Routes>
          <Route path="/" element={<RouteTransition><App /></RouteTransition>} />
          <Route path="/fingerprinting" element={<RouteTransition><Fingerprinting /></RouteTransition>} />
          <Route path="/account" element={<RouteTransition><Account /></RouteTransition>} />
          <Route path="/console" element={<RouteTransition><Console /></RouteTransition>} />
          <Route path="/mailkit" element={<RouteTransition><MailKit /></RouteTransition>} />
          <Route path="/privacy" element={<RouteTransition><PrivacyPolicy /></RouteTransition>} />
          <Route path="/tos" element={<RouteTransition><TOS /></RouteTransition>} />
          <Route path="*" element={<RouteTransition><Error /></RouteTransition>} />
        </Routes>
      </Router>
    </FirebaseProvider>
  </React.StrictMode>
);

reportWebVitals();
