import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useFirebase } from '../../utils/Firebase';
import logo from '../../assets/kearneyonlineLogo.png';
import './MailKit.css';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import InboxIcon from '@mui/icons-material/Inbox';
import SendIcon from '@mui/icons-material/Send';
import CampaignIcon from '@mui/icons-material/Campaign';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InventoryIcon from '@mui/icons-material/Inventory';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CreateIcon from '@mui/icons-material/Create';

function MailKit() {

    const [time, setTime] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { app } = useFirebase();
    const auth = getAuth(app);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isFolderOpen, setIsFolderOpen] = useState(false);

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleWindowResize);

    useEffect(() => {
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handlePreviewClick = () => {
        setIsFolderOpen(!isFolderOpen);
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        // Add the no-background class to the body element when the component mounts
        document.body.classList.add('no-background');

        // Remove the no-background class when the component unmounts
        return () => {
            document.body.classList.remove('no-background');
        };
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    if (loading) {
        return (
            <motion.div className='App'>
                <motion.div className='header'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0 }}
                >
                    <div className='date-time'>
                        <div className='logo-container'
                        onClick={() => navigate('/')}
                        >
                        <img src={logo} alt='logo' className='logo'/>
                        Kearney Labs 
                        </div>
                        <div className='time'>
                        {time.toLocaleTimeString()}
                        </div>
                    </div>
                </motion.div>

                <motion.div className='mailkit-loading'>
                    <HourglassFullIcon className='loading-icon'/>
                </motion.div>

            </motion.div>
        )
    }
    
    return (
            <motion.div className="App"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                
                <motion.div className='header'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0 }}
                >
                    <div className='date-time'>
                        <div className='logo-container'
                        onClick={() => navigate('/')}
                        >
                        <img src={logo} alt='logo' className='logo'/>
                        {auth.currentUser?.email}
                        </div>
                        <div className='time'>
                        {time.toLocaleTimeString()}
                        </div>
                    </div>
                </motion.div>

                <motion.div className='mailkit'>
                    <div className='mailkit-menu'>
                        <div className='mailkit-inbox-menu'>
                            <div className='button-group'> 
                                
                                <Tooltip title='Sent' placement='bottom'>
                                    <button className='button-ingroup'>
                                        <SendIcon sx={{fontSize: '1rem'}}/>
                                    </button>
                                </Tooltip>
                                
                                <Tooltip title='Archive' placement='bottom'>
                                    <button className='button-ingroup'>
                                        <InventoryIcon sx={{fontSize: '1rem'}}/>
                                    </button>
                                </Tooltip>

                                <Tooltip title='Inbox' placement='bottom'>
                                    <button className='button-ingroup selected'>
                                        <InboxIcon sx={{fontSize: '1rem'}}/>
                                    </button>
                                </Tooltip>
                                
                            </div>

                            <Tooltip title='Smart Summary' placement='bottom'>
                                <button>
                                    <AutoAwesomeIcon sx={{fontSize: '1rem'}}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className='search'>
                            <div className='search-bar' style={{margin: '0rem', borderRadius: '0.5rem', width: '100%' }}>
                                <SearchIcon sx={{ fontSize: 20, color: '#ffffff99', marginLeft: '0.5rem' }}/>
                                <input type='text' className='mailkit-search'
                                onChange={event => setSearch(event.target.value)}
                                value={search}
                                />
                            </div>
                        </div>
                        <div className='mailkit-campaign-menu'>
                            <Tooltip title='Campaigns' placement='bottom'>
                                <button className='mailkit-campaign-button'>
                                    <CampaignIcon sx={{fontSize: '1rem'}}/>
                                </button>
                            </Tooltip>

                            <Tooltip title='Compose' placement='bottom'>
                                <button className='mailkit-campaign-button'>
                                    <CreateIcon sx={{fontSize: '1rem'}}/>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <motion.div className='mailkit-email'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 }}}
                        exit={{ opacity: 0 }}
                    >
                        <div className={`mailkit-email-folder${isMobile && isFolderOpen ? ' open' : ''}`}>
                            
                            <motion.div className='preview-container' onClick={handlePreviewClick}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 }}}
                            exit={{ opacity: 0 }}
                            >
                                <div className='preview-subject'>
                                    Example Subject
                                    {/* <AttachmentIcon sx={{fontSize: 20, color: '#a0a0a0'}}/> */}
                                </div>
                                <div className='preview-sender'>
                                    example@example.com
                                </div>
                            </motion.div>

                            <motion.div className='preview-container' onClick={handlePreviewClick}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 }}}
                            exit={{ opacity: 0 }}
                            >
                                <div className='preview-subject'>
                                    720 Deal - Completed Paperwork
                                    <AttachmentIcon sx={{fontSize: 20, color: '#a0a0a0'}}/>
                                </div>
                                <div className='preview-sender'>
                                    prodlanders@gmail.com
                                </div>
                            </motion.div>

                            <motion.div className='preview-container' onClick={handlePreviewClick}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 }}}
                            exit={{ opacity: 0 }}
                            >
                                <div className='preview-subject'>
                                    New Placement - Gilbert Gottfried
                                    <AttachmentIcon sx={{fontSize: 20, color: '#a0a0a0'}}/>
                                </div>
                                <div className='preview-sender'>
                                    john@kearneyjohn.com
                                </div>
                            </motion.div>

                        </div>
                        <div className={`mailkit-email-content${isMobile && isFolderOpen ? ' open' : ''}`}>
                            <div className='close-email-content' onClick={handlePreviewClick}>
                                <ArrowUpwardIcon sx={{fontSize: '1rem'}}/>
                            </div>
                            <div className='mailkit-email-content-header'>
                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: '#d3d3d3', fontWeight: 'light'}}>
                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        <div className='mailkit-email-content-header-subject'>
                                            Example Subject
                                        </div>
                                        <div className='mailkit-email-content-header-sender'>
                                            <a href='mailto:'>example@example.com</a>
                                        </div>
                                    </div>
                                    Date
                                </div>
                            </div>
                            <div className='mailkit-email-content-body'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc. Donec euismod, nisl eget ultricies ultrices, nunc nunc aliquet nunc, vitae ultricies nisl nunc eget nunc.
                            </div>
                        </div>
                    </motion.div>

                </motion.div>


                {/* <motion.div className='footer'>
                    Mailkit v0.1-alpha
                </motion.div> */}
            
            </motion.div>
    )
}

export default MailKit;