import './RegisterPrompt.css'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import logo from './../assets/kearneyonlineLogo.png';
import { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useFirebase } from '../utils/Firebase';
import { useEffect } from 'react';
import Login from './Account/AuthComponent';
import Tooltip from '@mui/material/Tooltip';


// icons
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import MailIcon from '@mui/icons-material/Mail';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PianoIcon from '@mui/icons-material/Piano';
import AttributionIcon from '@mui/icons-material/Attribution';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

function RegisterPrompt() {

    const navigate = useNavigate();
    const [time, setTime] = useState(new Date());
    const [tier, setTier] = useState<string | null>(null);
    const { app } = useFirebase();
    const auth = getAuth(app);

    const handleTierChange = (newTier: string) => {
        if (newTier === tier) {
            setTier(null);
        } else if (newTier !== tier) {
            setTier(newTier);
        };
    };

    useEffect(() => {
        const timer = setInterval(() => {
          setTime(new Date());
        }, 1000);
        return () => {
          clearInterval(timer);
        }
      }, []);

    return (
        <motion.div className="register-prompt"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >

        <motion.div className='header'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            >
            <div className='date-time'>
                <div className='logo-container'
                onClick={() => navigate('/')}
                >
                <img src={logo} alt='logo' className='logo'/>
                Kearney Labs
                </div>
                <div className='time'>
                {time.toLocaleTimeString()}
                </div>
            </div>
        </motion.div>
                            
        <motion.div className='register-prompt-container'
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            exit={{ y: 100 }}
        >
            <div className='register-prompt-title'>
                Login
                <Tooltip title='This product is under development.
                By signing up, you understand that your free account data
                may be deleted at any time as we undergo development.' arrow>
                    <img src={logo} alt='logo' className='logo prompt-logo'/>
                </Tooltip>
            </div>

            <Login />
        </motion.div>

        <motion.div className='register-prompt-container disabled'
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            exit={{ y: 100, opacity: 0 }}
        >
            <div className='register-prompt-title'>
                LabKit Pro
                <Tooltip title='This product is under development.
                By signing up, you understand that your free account data
                may be deleted at any time as we undergo development.' arrow>
                    <img src={logo} alt='logo' className='logo prompt-logo'/>
                </Tooltip>
            </div>

            <div className='register-prompt-subtitle'>
                All accounts start on the free tier.
                After you login for the first time, you can upgrade to Pro anytime.
                <br />
                <br />
                All upgrades are disabled for dev. Everything is free as I deploy sh*t.
            </div>

            <div className='register-prompt-details'>
                
                <div className='register-prompt-tier'
                    onClick={() => handleTierChange('free')}
                >
                    <div className='register-prompt-tier-title'>
                        Free
                    </div>
                    <div className='register-prompt-subtitle text-center'>
                        $0/month
                    </div>
                </div>

                <div className='register-prompt-tier'
                    onClick={() => handleTierChange('pro')}
                >
                    <div className='register-prompt-tier-title'>
                        Pro
                    </div>
                    <div className='register-prompt-subtitle text-center'>
                        $5/month
                    </div>
                </div>

            </div>

        </motion.div>

            {tier && (
                <motion.div className='register-prompt-container'
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ y: 100, opacity: 0 }}
                >
                    {tier === 'free' ? (
                    <div className='compare-container'>
                        <div className='compare-title'>
                            Free
                            <div className='compare-icons'>
                                <Tooltip title='MidiGen' arrow>
                                    <PianoIcon />
                                </Tooltip>
                                <Tooltip title='MailKit' arrow>
                                    <MailIcon />
                                </Tooltip>
                                <Tooltip title='LicenseKit' arrow>
                                    <DesignServicesIcon />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='compare-subtitle'>
                            The free tier includes some of my most popular tools with generous monthly usage limits;
                            perfect for testing LabKit out.
                            <br />
                            <br />
                            Targeted for producers: MailKit, LicenseKit, and MidiGen are all included in the free tier.
                        </div>
                    </div>)
                    :
                    (<div className='compare-container'>
                        <div className='compare-title'>
                            Pro
                            <div className='compare-icons'>
                                <Tooltip title='Audio Fingerprinting' arrow>
                                    <FingerprintIcon />
                                </Tooltip>
                                <Tooltip title='MailKit' arrow>
                                    <MailIcon />
                                </Tooltip>
                                <Tooltip title='MidiGen' arrow>
                                    <PianoIcon />
                                </Tooltip>
                                <Tooltip title='LicenseKit' arrow>
                                    <DesignServicesIcon />
                                </Tooltip>
                                <Tooltip title='CreditKit' arrow>
                                    <AttributionIcon />
                                </Tooltip>
                                <Tooltip title='Single Service Management' arrow>
                                    <ConnectWithoutContactIcon />
                                </Tooltip>
                                <div style={{fontSize: '0.6rem'}}>
                                    + More
                                </div>
                            </div>
                        </div>
                        <div className='compare-subtitle'>
                            The Pro tier includes all of my tools with no usage limits;
                            perfect for any professional in the music industry.
                            <br />
                            <br />
                            Automate your catalog protection and increase your peer-to peer productivity with the best tools in the industry.
                        </div>
                    </div>)}
                </motion.div>
            )}

        </motion.div>
    );
};

export default RegisterPrompt;