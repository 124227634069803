import logo from '../assets/kearneyonlineLogo.png';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function TOS() {

    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <motion.div className='App'>
                <motion.div className='header'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0 }}
                >
                    <div className='date-time'>
                        <div className='logo-container'
                        onClick={() => navigate('/')}
                        >
                        <img src={logo} alt='logo' className='logo'/>
                        Kearney Labs 
                        </div>
                        <div className='time'>
                        {time.toLocaleTimeString()}
                        </div>
                    </div>
                </motion.div>

                <motion.div className='body' style={{padding: '1rem', width: 'auto', display: 'flex', flexDirection: 'column'}}>
                    <h1>LabKit - Terms of Service</h1>
                    <h5>Effective Date: 9/1/23</h5>

                    <h2>1. Acceptance of Terms</h2>

                    By signing up with Google and using the service "LabKit" hosted at https://tools.kearneyjohn.com ("LabKit" or "the Service"), you agree to comply with and be bound by these Terms of Service ("TOS"). If you do not agree to these TOS, please do not use LabKit.

                    <h2>2. Description of Service</h2>

                    2.1. User Registration: To use LabKit, you will be required to sign up with your Google account. We will collect your name, email, profile picture, and authentication token from Google.
                    <br/>
                    <br/>
                    2.2. Data Security: We prioritize the security of your data and use industry-standard Backend as a Service (BAAS) services like Google Firebase to protect user data. However, please note that while in alpha development, LabKit may experience bugs.

                    <h2>3. Data Usage</h2>

                    By using LabKit and uploading information, you consent to sharing non-sensitive information with third-party services as needed for LabKit's functionalities, such as audio fingerprinting and AI-enabled mail summarization. We share as little information as possible and never share sensitive data.

                    <h2>4. Data Collecition and Storage</h2>

                    We only collect and store the data you provide during sign-in. LabKit does not store audio files, user emails, user email metadata, or any other sensitive information.

                    <h2>5. Third-Party Services</h2>

                    LabKit may utilize third-party APIs to enhance your experience by aggregating information in one place. We are committed to safeguarding your data and will never share sensitive information with these third-party services.

                    <h2>6. Updates and Changes</h2>

                    LabKit is in alpha development, and as such, it may contain bugs. We reserve the right to add new tools and features at any time. You have the choice not to use these additional tools.

                    <h2>7. Your Data</h2>

                    You can request data deletion or download your data at any time. Upon deletion, all your information is immediately purged and cannot be recovered.

                    <h2>8. Ownership and IP</h2>

                    John Kearney, on behalf of Kearney Labs, owns all rights to the code that comprises LabKit. We do not claim ownership or copyright of any information uploaded to the website, only the code.

                    <h2>9. Termination</h2>

                    Kearney Labs reserves the right to terminate any LabKit account at any time, without prior notice, and without providing a reason.

                    <h2>10. Disclaimer of Warranties</h2>

                    LabKit is under active development; Kearney Labs (or any affiliated individuals) shall not be responsible or liable for any damages incurred by using the service. Users assume all risk associated with its use.

                    <h2>11. Contact</h2>

                    For support or inquiries, you can contact us at dev@kearneyjohn.com. You may also receive occasional product updates or notifications about TOS updates via email from noreply@kearneyjohn.com.

                    <h2>12. Governing Law</h2>

                    These TOS are governed by and construed in accordance with the laws of North Carolina, United States of America. Any disputes arising from or relating to these TOS shall be subject to the exclusive jurisdiction of the courts in the United States of America.

                    <h2>13. Changes to TOS</h2>

                    LabKit may update these TOS at any time. Continued use of the Service after such changes constitutes your acceptance of the revised TOS. If you do not agree to the revised TOS, discontinue use of the Service immediately and contact support for data deletion.
                </motion.div>

            </motion.div>
    )
}

export default TOS;