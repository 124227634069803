import getStripe from "./initializeStripe";
import { useFirebase } from "../Firebase";
import { getFirestore, doc, addDoc, onSnapshot, collection } from "firebase/firestore";
import { FirebaseApp } from "firebase/app";

export const createCheckoutSession = async (uid: string, app: FirebaseApp) => {
    const firestore = getFirestore(app);

    // Reference to the subcollection 'checkout_sessions' inside the user's document
    const userRef = doc(firestore, 'users', uid);
    const checkoutSessionsCollectionRef = collection(userRef, 'checkout_sessions');

    // Create a new checkout session document in the subcollection
    const newCheckoutSessionRef = await addDoc(checkoutSessionsCollectionRef, {
        price: 'price_1NjH9rAF0lxsx5yIkMxIUyNx',
        success_url: window.location.origin,
        cancel_url: window.location.origin,
    });

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(newCheckoutSessionRef, async (snap: any) => { // Mark the callback as async
        const { sessionId } = snap.data();
        if (sessionId) {
            console.log("Valid session ID... waiting for redirect to Stripe Checkout");
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await getStripe()
            await stripe?.redirectToCheckout({ sessionId });
            console.log("Redirected to Stripe Checkout");
        }
    });
}
