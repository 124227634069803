function handleCommand(message: string, clearMessages: () => void) {
    // the message is the first word, every other word is an argument
    const command = message.split(' ')[0];
    const args = message.split(' ').slice(1);

    if (message === 'help') {
        return `This console is for developers and special cases only. Do not use if you are unsure what you're doing.`;
    } else if (message === 'clear') {
        clearMessages();
        return;
    } else if (message === 'time') {
        return new Date().toLocaleTimeString();
    } else {
        return `Unknown command: ${message}`;
    }
}

export default handleCommand;