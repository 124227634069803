import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


export default async function isUserPro(app: any): Promise<boolean> {
    const auth = getAuth(app);
    const firestore = getFirestore(app);
    const user = auth.currentUser;

    await user?.getIdToken(true);
    const decodedToken = await user?.getIdTokenResult();

    if (decodedToken?.claims.stripeRole === 'pro') {
        return true;
    } else {
        return false;
    }
}