import { useState, useEffect } from 'react';
import { useFirebase } from '../Firebase';
import isUserPro from './isUserPro';

export default function useProStatus(user: any) {
    const [proStatus, setProStatus] = useState<boolean>(false);
    const { app } = useFirebase();
    useEffect(() => {
        if (user) {
           
            const checkProStatus = async function () {
                setProStatus(await isUserPro(app));
            };
            checkProStatus();
        }
    }, [user]);

    return proStatus;
}