import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/kearneyonlineLogo.png';
import './Account/Account.css';
import { motion } from 'framer-motion';
import { useFirebase } from "../utils/Firebase";
import { getAuth, signOut } from "firebase/auth";
import GoogleIcon from '@mui/icons-material/Google';
import Alert from '@mui/material/Alert';
import { createCheckoutSession } from '../utils/stripe/createCheckoutSession';
import useProStatus from '../utils/stripe/useProStatus';

function Account () {

    const [time, setTime] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [uid, setUid] = useState<string | null>(null);
    const { app } = useFirebase();
    const auth = getAuth(app);
    const [userPhotoURL, setUserPhotoURL] = useState<string | null>(null);

    const user = auth.currentUser;
    const userIsPro = useProStatus(user);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUid(user.uid);
                console.log(user.uid);
                setLoading(false);
                if (user.photoURL !== null && user.photoURL !== '') {
                    setUserPhotoURL(user.photoURL);
                }
            } else {
                setUid(null);
                navigate('/');
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
        setTime(new Date());
    }, 1000);
        return () => {
            clearInterval(timer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <div className='header'>
                <div className='date-time'>
                    <div className='logo-container'
                        onClick={() => navigate('/')}
                    >
                        <img src={logo} alt='logo' className='logo'/>
                        Kearney Labs
                    </div>
                    <div className='time'>
                        {time.toLocaleTimeString()}
                    </div>
                </div>
            </div>

            <motion.div className='ac-body'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {!loading && uid ?
                <>
                    <motion.div className='ac-container'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    >
                        <div className='account-info'> 
                            {userPhotoURL ? 
                                <img src={userPhotoURL} alt='user' className='profile-pic' />
                            : null}
                            <div className='name'>
                                {auth.currentUser?.displayName}
                            </div>
                            <div className='email'>
                                {auth.currentUser?.providerData[0].providerId.toLowerCase() === 'google.com' ?
                                <GoogleIcon /> : null}
                                {auth.currentUser?.email}
                            </div>
                        </div>
                        <div className='links'>
                            <button className='onHover'
                                onClick={(event) => {
                                    event.preventDefault();
                                    signOut(auth).then(() => {
                                        console.log('Signed Out')
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                }
                            }
                            >
                                Sign Out
                            </button>
                        </div>
                    </motion.div>

                    <motion.div className='ac-container disabled'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        Subscription: {userIsPro ? 'Pro 🚀' : 'Free'}
                        {!userIsPro ?
                        <button className='onHover'
                            onClick={() => createCheckoutSession(uid, app)}>
                            Upgrade to Pro
                        </button>
                        : null}

                    </motion.div>
                </>
                :
                null}

                <Alert severity="warning" color="warning">
                    Account locked to Free Tier
                </Alert>

            </motion.div>

        </div>
        
    );
};

export default Account;