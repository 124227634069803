import logo from '../assets/kearneyonlineLogo.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Fingerprinting/Fingerprinting.css'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { motion } from 'framer-motion';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import { FaSpotify } from 'react-icons/fa';
import { SiApplemusic } from 'react-icons/si';

function Fingerprinting() {

    const [audioResultData, setAudioResultData] = useState<any>(null);
    const [message, setMessage] = useState<string | null>('Awaiting File...');
    const [composers, setComposers] = useState<string[]>([]);
    
    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
        setTime(new Date());
    }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    

    const handleFingerprint = () => {
        // prompt the user for an mp3 upload
        const REACT_APP_AUDD_KEY: string | undefined = process.env.REACT_APP_AUDD_KEY as string;
        setMessage('Prompting File Upload...');
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.mp3';
        fileInput.onclick = () => {
            setAudioResultData(null);
        };
        fileInput.oncancel = () => {
            setMessage('Awaiting File...');
        };
        fileInput.click();
        fileInput.onchange = async () => {
            setMessage('Uploading File...');
            if (fileInput.files && REACT_APP_AUDD_KEY) {
                const formData = new FormData();
                formData.append('api_token', REACT_APP_AUDD_KEY);
                formData.append('file', fileInput.files[0]);
                formData.append('return', 'apple_music,spotify');
                try {
                    setMessage('Searching...');
                    const response = await fetch("https://api.audd.io/", {
                        method: 'POST',
                        body: formData
                    });
    
                    if (response.ok) {
                        setMessage(null);
                        const result = await response.json();
                        // console.log(result);
                        setAudioResultData(result);
                        setComposers(result.result.apple_music.composerName.split(','));
                    } else {
                        setMessage('No results found. Try again!')
                        console.error('Request failed with status:', response.status);
                    }
                } catch (error) {
                    setAudioResultData(null);
                    setMessage('No results found. Try again!');
                }
            }
        };
    };
    
    

    return (
            <div className="App">
                <div className='header'>
                    <div className='date-time'>
                        <div className='logo-container'
                            onClick={() => navigate('/')}
                        >
                            <img src={logo} alt='logo' className='logo'/>
                            Kearney Labs
                        </div>
                        <div className='time'>
                            {time.toLocaleTimeString()}
                        </div>
                    </div>
                </div>

                <div className='fp-body'>

                    <div className='fp-container-row'
                    onClick={handleFingerprint}
                    >
                        <TroubleshootIcon sx={{ fontSize: 20, color: '#ffffff' }}/>
                        Search
                    </div>

                    {audioResultData && audioResultData.result ?
                        <motion.div className='fp-track-container'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className='fp-track-main'>
                                <img src={audioResultData.result.spotify.album.images[0].url} alt='album art' className='album-art'
                                    onClick={() => window.open(audioResultData.result.song_link)}
                                />
                                <div className='fp-track-info'>
                                    <div className='fp-track-artist'
                                        onClick={() => window.open(audioResultData.result.spotify.artists[0].external_urls.spotify, '_blank')}
                                    >
                                        {audioResultData.result.artist}
                                    </div>
                                    <div className='fp-track-title'>{audioResultData.result.apple_music.albumName} - {audioResultData.result.title}</div>
                                    <div className='fp-track-title'>ISRC: {audioResultData.result.apple_music.isrc}</div>
                                </div>
                            </div>
                            <div className='fp-track-links'>
                                <FaSpotify style={{fontSize: '1.25rem'}}
                                    onClick={() => window.open(audioResultData.result.spotify.external_urls.spotify, '_blank')}
                                />
                                <SiApplemusic style={{fontSize: '1.25rem'}}
                                    onClick={() => window.open(audioResultData.result.apple_music.url, '_blank')}
                                />
                            </div>

                            {audioResultData.result.apple_music.composerName ?  
                                <div className='fp-track-composer'>
                                    <div className='fp-track-credits'>
                                        {audioResultData.result.label} - {audioResultData.result.release_date.toString().split('-')[0]}
                                    </div>
                                    <div className='fp-track-composer-list'>
                                        {composers.map((composer: string) => {
                                            return (
                                                <div className='fp-track-composer-item' key={composer}>
                                                    {composer}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                :
                                null
                            }

                        </motion.div>
                        :
                        null
                    }

                    {message ?
                        <motion.div className='fp-message'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <HourglassFullIcon sx={{ fontSize: 20, color: '#ffffff' }} className='loading-icon'/>
                            {message}
                        </motion.div>
                        :
                        null
                    }
                </div>

            </div>
    );
}

export default Fingerprinting;