import './App.css';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import logo from './assets/kearneyonlineLogo.png';
import Tooltip from '@mui/material/Tooltip';
import MailIcon from '@mui/icons-material/Mail';
import SearchIcon from '@mui/icons-material/Search';
import InsightsIcon from '@mui/icons-material/Insights';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import SmsIcon from '@mui/icons-material/Sms';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { getAuth } from 'firebase/auth';
import { useFirebase } from './utils/Firebase';
import RegisterPrompt from './tools/RegisterPrompt';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

function App() {

  const [time, setTime] = useState(new Date());
  const [showRegisterPrompt, setShowRegisterPrompt] = useState(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { app } = useFirebase();
  const auth = getAuth(app);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setShowRegisterPrompt(false);
      } 
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);

  const apps = [
    {
      name: 'Account',
      icon: <PersonIcon sx={{ fontSize: 45, color: '#ffffff99' }}/>,
      disabled: false,
      slug: 'account'
    },
    {
      name: 'Audio Fingerprinting',
      icon: <FingerprintIcon sx={{ fontSize: 45, color: '#ffffff99' }}/>,
      disabled: false,
      slug: 'fingerprinting'
    },
    {
      name: 'Cloud Console',
      icon: <DeveloperBoardIcon sx={{ fontSize: 45, color: '#ffffff99' }}/>,
      disabled: false,
      slug: 'console'
    },
    {
      name: 'MailKit',
      icon: <MailIcon sx={{ fontSize: 45, color: '#ffffff99' }}/>,
      disabled: true,
      slug: 'mailkit'
    }
  ]

  const filteredApps = apps.filter(app =>
    app.name.toLowerCase().includes(search.toLowerCase())
  );

  if (showRegisterPrompt) return (
    <RegisterPrompt />
  );

  return (
    
      <motion.div className="App"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0 }}
      >
        {showRegisterPrompt && <RegisterPrompt />}

        <motion.div className='header'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0 }}
        >
          <div className='date-time'>
            <div className='logo-container'
              onClick={() => navigate('/')}
            >
              <img src={logo} alt='logo' className='logo'/>
              Kearney Labs
            </div>
            <div className='time'>
              {time.toLocaleTimeString()}
            </div>
          </div>
        </motion.div>

        

        <motion.div className='search'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 }}}
          exit={{ opacity: 0 }}
        >
          <div className='search-bar'>
            <SearchIcon sx={{ fontSize: 25, color: '#ffffff99', marginLeft: '0.5rem' }}/>
            <input type='text' className='search-input'
              onChange={event => setSearch(event.target.value)}
              value={search}
            />
          </div>
        </motion.div>

        <div className="body">
          {filteredApps.map((app, index) => (
            <Tooltip title={app.name} placement="bottom" key={index}>
              <motion.div
                className={app.disabled ? 'tool-app-icon disabled' : 'tool-app-icon'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 + (index * 0.1) }}}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => navigate(app.slug)}
              >
                {app.icon}
              </motion.div>
            </Tooltip>
          ))}
        </div>

        <div className='footer'>
          <a href='https://shop.kearneyjohn.com/privacy-policy/' target='_blank' rel='noreferrer'>
            [ Privacy ]
          </a>
        </div>
      </motion.div>
  );
}

export default App;
