import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import GoogleIcon from '@mui/icons-material/Google';
import './AuthComponent.css';
import { useFirebase } from '../../utils/Firebase';
import 'firebase/firestore'

export default function Login() {
    const navigate = useNavigate();
    const { app } = useFirebase();
    const auth = getAuth(app);
    const firestore = getFirestore(app);
    const user = auth.currentUser;

    async function signInWithGoogle() {
        const provider = new GoogleAuthProvider()
        try {
            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            console.log(user);

            const userRef = doc(firestore, 'users', user?.uid);
            await setDoc(userRef, {
                uid: user?.uid,
                email: user?.email,
                displayName: user?.displayName,
                photoURL: user?.photoURL,
                provider: user?.providerData[0].providerId,
            });

            navigate('/');
        } catch (error: any) {
            await signOut(auth);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        }
    }
    

    return (
        <motion.div className='auth-container'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <button className='onHover'
                onClick={signInWithGoogle}
                style={{width: '100%'}}
            >
                <GoogleIcon />
                Sign in with Google
            </button>
        </motion.div>
    )
}