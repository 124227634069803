import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Stripe | null;

export const initializeStripe = async () => {
    if (!stripePromise) {
        console.log("Initializing Stripe with key: ", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY?.toString());
        stripePromise = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
        );
    }
    return stripePromise;
};

export default initializeStripe;